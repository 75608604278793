














import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OReviewerList: () => import('@/components/organisms/event/abstractModule/o-reviewer-list.vue')},

  setup(){

    return {}
  }
})
